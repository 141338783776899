import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import {
  saveAndNextInsection,
  submitTestApi,
} from "../../../services/saveAndNext";

const IitSectionTimer = (props) => {
  const [sectionTimes, setSectionTime] = useState(0);
  const [showTimer, setShowTimer] = useState("");

  const SessionTimer = () => {
    let sectionTimeloc = sectionTimes;
    if (sectionTimeloc) {
      saveAndNextInsection(sectionTimes);
    } else {
      submitTestApi(props);
    }

    let sectionTimer = "";
    const interval = setInterval(() => {
      sectionTimer =
        Math.floor(sectionTimeloc / 3600) +
        " : " +
        ("0" + (Math.floor(sectionTimeloc / 60) % 60)).slice(-2) +
        " : " +
        ("0" + (sectionTimeloc % 60)).slice(-2);
      setShowTimer(sectionTimer);
      sectionTimeloc = sectionTimeloc - 1;
      sessionStorage.setItem("SessionleftTimeToSubmit", sectionTimeloc);
      if (sectionTimeloc <= 1) {
        clearInterval(interval);
        let index = props.allSection.findIndex((data) => {
          return (
            props?.state?.ques_data[props.state?.activeQuesInd]?.subject_id +
              "#" +
              props?.state?.ques_data[props.state?.activeQuesInd]?.section_id ==
            data
          );
        });

        setTimeout(() => {
          changeSection(props.allSection[index + 1]);
        }, 1000);
      }
    }, 1000);
  };

  const changeSection = async (sectionId) => {
    setSectionTime(0);
    props.dispatch({ type: "SELECTED_SUBJECT", selectedSection: sectionId });
    let time = props.state.subjects_data.find(
      (element) =>
        "" + element.subject_id + "#" + element.section_id == sectionId
    );
    await setSectionTime(parseInt(time?.section_time) * 60);
    // setTimeout(() => {
    //   saveAndNext(props);
    // }, 1000);
  };

  useEffect(() => {
    if (props.allSection.length > 0) {
      if (props?.state?.attempt_data?.current_section_id) {
        changeSection(props?.state?.attempt_data?.current_section_id);
      } else {
        changeSection(props.allSection[0]);
      }
    }
  }, []);

  useEffect(() => {
    if (sectionTimes != 0) {
      SessionTimer();
    }
  }, [sectionTimes]);

  return (
    <>
      <p>
        <div className="timerDiv">
          <span className="timeHeader">Time Left : </span>
          <div style={{ display: "flex" }}>
            <div className="timerun">{showTimer.split(":")[0]}</div>{" "}
            <span style={{ marginLeft: "5px", marginRight: "5px" }}>: </span>
            <div className="timerun">{showTimer.split(":")[1]}</div>
            <span style={{ marginLeft: "5px", marginRight: "5px" }}>: </span>
            <div className="timerun">{showTimer.split(":")[2]}</div>
          </div>
        </div>
      </p>
    </>
  );
};
const mapStateToProps = (state) => ({ state: state });
export default connect(mapStateToProps)(IitSectionTimer);
