import React, { useEffect, useState } from "react";
import './instructions.scss'
import { connect } from "react-redux";
import clintlogo from "../../assets/images/default_avtar.png"
import EnglishInstruction from "../instructions/english"
import HindiInstraction from "./hindi";
import KanndaInstraction from "./kannda";
import api from "../../api";
import Loader from "../loader";
import el_user from "../../assets/icons/el_user.svg";
// import { FullScreen, useFullScreenHandle } from "react-full-screen";
const Instractions = (props) => {
  const [multiLanguageslist, setMultiLanguageslist] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState()
  const [language, setLanguage] = useState("English")
  const [startingBtnText, setStartingBtnText] = useState('Start Test')
  const [testEnabled, setTestEnabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [accept_terms, SetAccept_terms] = useState(false)
  const [serverTimeStamp, setServerTimeStamp] = useState(0)
  const [leftHours, setLeftHours] = useState("")
  const [leftDays, setLeftDays] = useState("")
  const [leftMins, setLeftMins] = useState("")
  const [leftSecs, setLeftSecs] = useState("")
  // const handle = useFullScreenHandle();

  let languageJSON = [
    {
      'id': 1,
      'title': 'English'
    },
    {
      'id': 2,
      'title': 'Hindi'
    },
    {
      'id': 3,
      'title': 'Kannada'
    }
  ]
  useEffect(() => {

    // handle.enter()

    if (props.state.test_data == undefined || props.state.student_data == undefined) {
      window.location.replace("/")
    }
    else {

      if (props.state.test_data.is_multi_lingual == 1) {
        setMultiLanguageslist(props.state.test_language)
        if (multiLanguageslist.length) {
          setSelectedLanguage(multiLanguageslist[0].id);
          changeByLanguage(selectedLanguage);
        }
      }
      if (props.state.student_data.userid !== 1234567890) {
        if (props.state.test_data.test_type_id == 2) {
          if (props.state.attempt_data && props.state.attempt_data.attempt_count > 0) {
            setStartingBtnText('Restart Test');
          }
          else if (props.state.attempt_data.attempt_data !== null) {
            setStartingBtnText('Resume Test');
          }
          else {
            setStartingBtnText('Start Test');
          }
        }
        else {
          if (props.state.attempt_data.attempt_data == null) {
            setStartingBtnText('Start Test');
          }
          else {
            setStartingBtnText('Resume Test')
          }
        }

      }
      else {
        setStartingBtnText('Start Test');
      }
      initTimer();
    }

  }, [])
  const changeByLanguage = (lang) => {
    if (isNaN(lang)) {
      let temp = multiLanguageslist.find(ob => ob.title == lang);
      temp ? setLanguage(temp.title) : setLanguage(lang)
    }
    else {
      setLanguage(lang)

    }
  }

  const initTimer = async () => {
    let SrvrTimeStamp = 0
    setLoading(true)
    let request = {
      url: `/v1/examPanel/time`,
      headers: {
        "X-Device-Info": "eyJkZXZpY2UiOiJVbmtub3duIiwiYnJvd3NlciI6IkNocm9tZSA5OS4wLjQ4NDQuODIiLCJvcyI6IldpbmRvd3Mgd2luZG93cy0xMCJ9",
        "X-Device-Key": "ozfinggcn7"
      },
    }
    await api.getAuth(request).then((response) => {
      setLoading(false)
      setServerTimeStamp(response.data.timestamp)
      SrvrTimeStamp = response.data.timestamp;
      sessionStorage.setItem('exam-date', serverTimeStamp);
    },
      errors => {
        setLoading(false)

      });
    const x = setInterval(function () {
      SrvrTimeStamp = SrvrTimeStamp + 1;
      let distance = (props.state.test_data.test_start_timestamp - SrvrTimeStamp);
      let formattedLeftTimeToStart = Math.floor(distance / 3600)
        + ' : ' + ("0" + (Math.floor(distance / 60) % 60)).slice(-2)
        + ' : ' + ("0" + distance % 60).slice(-2);
      let leftHours = Math.floor(distance / 3600);
      let leftDays = Math.floor(leftHours / 24);
      let leftMins = ("0" + (Math.floor(distance / 60) % 60)).slice(-2);
      let leftSecs = ("0" + distance % 60).slice(-2);

      setLeftDays(leftDays)
      setLeftHours(leftHours)
      setLeftMins(leftMins)
      setLeftSecs(leftSecs)
      if (distance <= 0) {
        clearInterval(x);
        setTestEnabled(true);
      }
    }, 1000);
  }

  return (<>

    {
      loading &&
      <div>
        <Loader />
      </div>
    }

    <div class="s1-v">
      <div class="header">
        <div>
          <a href="#">
            <button>Online test</button>
          </a>
        </div>
        <p>{props.state.institute_data.account_name !== "" ? props.state.institute_data.account_name : props.state.client_name}</p>
        {(props.state.student_data.userid == 1234567890 && !props.state.registrationdata) && <a>
          <button>Test Preview</button>
        </a>}
      </div>
      <div class="height">
        <div class="h1-gg">
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItem: "center", height: "35px", marginBottom: "5px", borderBottom: "1px solid #ddd" }}>
            <p className="instructionTitle" style={{ marginBottom: "20px", marginTop: "10px" }}>Instructions</p>
            {
              props.state.test_data.is_multi_lingual == 1 && multiLanguageslist.length &&
              <span className="float-right" style={{ marginTop: "4px" }} >
                <select name="language-select" className="custom-form-input" onChange={(event) => changeByLanguage(event.target.value)}>
                  {
                    languageJSON.map((item, index) => (
                      <option value={item.title}>{item.title}</option>
                    ))
                  }
                </select>
              </span>
            }
          </div>
          <div>
            <div style={{ height: "90%" }}>
              {
                language == "English" &&
                <EnglishInstruction instruction={props.state.test_data.instruction_content == null ? atob(props.state.test_data.instruction_description).replace(/(<([^>]+)>)/ig, '') : props.state.test_data.instruction_content[0].description} />
              }{
                language == "Hindi" && <HindiInstraction instruction={props.state.test_data.instruction_content == null ? atob(props.state.test_data.instruction_description).replace(/(<([^>]+)>)/ig, '') : props.state.test_data.instruction_content[0].description} />}
              {
                language == "Kannada" &&
                <KanndaInstraction instruction={props.state.test_data.instruction_content == null ? props.state.test_data.instruction_description : props.state.test_data.instruction_content[0].description} />

              }
              {
                props.state.test_data.is_multi_lingual == 1 && multiLanguageslist.length &&
                <div >
                  {
                    language == 'English' ? <div className="text-danger">Please note all questions will appear in your default language. This language can be changed for a particular question later on.</div> : language == 'Hindi' ?
                      <div className="text-danger">कृपया ध्यान दें कि सभी प्रश्न आपकी चयनित डिफ़ॉल्ट भाषा में दिखाई देंगे। इस भाषा को बाद में किसी विशेष प्रश्न के लिए बदला जा सकता है ।</div> : <div className="text-danger">ದಯವಿಟ್ಟು ನಿಮ್ಮ ಎಲ್ಲಾ ಡೀಫಾಲ್ಟ್ ಭಾಷೆಯಲ್ಲಿ ಕಾಣುವಿರಿ. ನಂತರ ನಿರ್ದಿಷ್ಟ ಪ್ರಶ್ನೆಗೆ ಈ ಭಾಷೆಯನ್ನು ಬದಲಾಯಿಸಬಹುದು.</div>
                  }
                </div>
              }


            </div>
          </div>
          {
            (testEnabled == true) &&
            <label style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
              <input type="checkbox" id="en_ch" onChange={((event) => event.target.checked == true ? SetAccept_terms(true) : SetAccept_terms(false))} />
              {

                language == 'English' ?
                  <span className="confirmationText" style={{ marginLeft: "5px", opacity: (accept_terms ? "1.0" : "0.5") }}> I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare  that I am not in possession of / not wearing / not  carrying any prohibited gadget like mobile phone, bluetooth  devices  etc. /any prohibited material with me into the Examination Hall.I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include ban from future Tests / Examinations</span> :
                  language == 'Hindi' ?
                    <span className="confirmationText" style={{ marginLeft: "5px", opacity: (accept_terms ? "1.0" : "0.5") }}> मैंने उपरोक्त सभी निर्देशों को पढ़ और समझ लिया है। मेरे लिए आवंटित सभी कंप्यूटर हार्डवेयर उचित काम करने की स्थिति में हैं। मैं घोषणा करता हूं कि मैं किसी भी प्रकार के निषिद्ध गैजेट जैसे मोबाइल फोन, ब्लूटूथ डिवाइस इत्यादि / परीक्षा हॉल में मेरे साथ किसी भी प्रकार की निषिद्ध सामग्री नहीं हैं । मैं सहमत हूं कि निर्देशों का पालन न करने के मामले में, मैं इस टेस्ट और अनुशासनात्मक कार्रवाई के लिए उत्तरदायी होऊँगा, जिसमें भविष्य मे होने वाले टेस्ट / परीक्षाओं से प्रतिबंध भी शामिल हो सकता है ।</span> :
                    <span className="confirmationText" style={{ marginLeft: "5px", opacity: (accept_terms ? "1.0" : "0.5") }}> ನಾನು ಸೂಚನೆಗಳನ್ನು ಓದಿದ್ದೇನೆ ಮತ್ತು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇನೆ. ನನಗೆ ಹಂಚಿಕೊಂಡಿರುವ ಎಲ್ಲಾ ಕಂಪ್ಯೂಟರ್ ಯಂತ್ರಾಂಶಗಳು ಸರಿಯಾದ ಕೆಲಸ ಸ್ಥಿತಿಯಲ್ಲಿವೆ. ಮೊಬೈಲ್ ಫೋನ್, ಬ್ಲೂಟೂತ್ ಸಾಧನಗಳು ಮುಂತಾದ ಯಾವುದೇ ನಿಷೇಧಿತ ಗ್ಯಾಜೆಟ್ ಅನ್ನು ನಾನು ಹೊಂದುವುದಿಲ್ಲ / ಅಲ್ಲವೆಂದು / ನನ್ನೊಂದಿಗೆ ಯಾವುದೇ ನಿಷೇಧಿತ ವಿಷಯವನ್ನು ಎಕ್ಸಾಮಿನೇಷನ್ ಹಾಲ್ಗೆ ಹೊಂದುವುದಿಲ್ಲ ಎಂದು ನಾನು ಘೋಷಿಸುತ್ತೇನೆ. ಸೂಚನೆಗಳಿಗೆ ಅಂಟಿಕೊಳ್ಳದಿದ್ದಲ್ಲಿ, ನಾನು ಈ ಪರೀಕ್ಷೆಯಿಂದ ಮತ್ತು / ಅಥವಾ ಶಿಸ್ತಿನ ಕ್ರಮಕ್ಕೆ ತೆರಬೇಕಾದ ಹೊಣೆಗಾರಿಕೆಯನ್ನು ಹೊಂದಿರಬೇಕು, ಇದು ಭವಿಷ್ಯದ ಪರೀಕ್ಷೆಗಳು / ಪರೀಕ್ಷೆಗಳಿಂದ ನಿಷೇಧವನ್ನು ಒಳಗೊಂಡಿರಬಹುದು </span>
              }
            </label>

          }
          {(testEnabled == false)
            &&
            <div className="row col-md-12 text-center countdown-clock"  >
              <p className="label">Exam Starts In</p>
              <div className="d-block timer" style={{ width: "100%" }}>
                <div className="d-inline-block clock-pallet">
                  <p className="left-day left-time-value">{leftDays}</p>
                  <p className="left-time-label">Days</p>
                </div>
                <div className="d-inline-block clock-pallet">
                  <p className="left-hours left-time-value">{leftHours}</p>
                  <p className="left-time-label">Hrs</p>
                </div>
                <div className="d-inline-block clock-pallet">
                  <p className="left-minutes left-time-value">{leftMins}</p>
                  <p className="left-time-label">Min</p>
                </div>
                <div className="d-inline-block clock-pallet">
                  <p className="left-seconds left-time-value">{leftSecs}</p>
                  <p className="left-time-label">Sec</p>
                </div>
              </div>
            </div>

          }
        </div>
        <div class="name_details">
          <img src={el_user} />
          <div class="br"></div>
          <p>Signed in as</p>

          {props.state.student_data.userid == 1234567890 ? <span>{props.state.registrationdata?.name ?? "User"}</span> : <span>{props.state.student_data.name}</span>}
          <div class="br"></div>
          {/* <p>Mobile Number</p>
          {props.state.student_data.userid == 1234567890 ? <span>{props.state.registrationdata?.number ?? "1234567890"}</span> : <span>{props.state.student_data.phone}</span>} */}

        </div>

      </div>

      <button style={{opacity: (accept_terms ? "1.0" : "0.5") }} onClick={() => window.location.replace("/panel")} disabled={!accept_terms} >{startingBtnText}</button>
    </div>


    {/* </FullScreen> */}
  </>)

}
const mapStateToProps = state => ({ state: state })
export default connect(mapStateToProps)(Instractions)
