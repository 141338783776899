import React from "react";
import "./illegalmsg_popup.css"
import hand from "../../assets/icons/hand.svg";
import Icon from "../../assets/icons/Icon.svg";
import Vector from "../../assets/icons/Vector.svg";
import { submitTestApi } from "../../services/saveAndNext";
import { connect } from "react-redux";
const IllegalMsg = (props) => {
const onSubmitFun=()=>{
  props.setillegalOpen(false)
  submitTestApi(props)
}

  return (<>
    <div className="ga-modal-wrapper">
      <div className="ga-modal-container">
        <div className="ga-modal">
          { (props.limit - props.illegalCount) > 0 ? <div className="ga-modal-body text-center">
          <div class="modal-content">
                <div class="modal-body-sm">
                    <div class="headerv">
                        <img src={Icon}/>
                        <span>Illegal Action Detected !</span>
                        <button class="close" onClick={() => { props.setillegalOpen(false) }}>
                            <img src={Vector}/>
                        </button>
                    </div>
                    <p>You are not allowed to switch applications or move away from test window. Test will be
                        auto-submitted once you exceed the maximum limit.</p>
                    <span>Attempts Remaining: {props.limit - props.illegalCount}</span>
                    <button onClick={() => { props.setillegalOpen(false) }}>Agree and Continue</button>
                </div>
            </div>
            </div>
          :<div class="modal-content">
            <div class="modal-body-sm">
              <div class="headerv">
                <img src={hand} />
                <span>Illegal Action Limit Exceeded !</span>
              </div>
              <p>You have exeeded the limit for switching test window. This test will now auto submit.</p>

              <button onClick={()=>onSubmitFun()}>Okay</button>
            </div>    
          </div>}
        </div>
      </div>
      </div>
  </>)
}

const mapStateToProps = state => ({...state })
export default connect(mapStateToProps)(IllegalMsg)
