import React from "react"
const MandatoryQuestion = (props)=>{
    return(<>
    <div className="ga-modal-wrapper" >
  <div className="ga-modal-container">
    <div className="ga-modal">  
      <div className="ga-modal-body text-center">
        <p className="mb-3 text-bold text-danger">All Questions are Mandatory ! </p>
        <div style={{textAlign: "center",marginTop: "1em"}}>
          <button style={{width: "160px"}} className="btn btn-light btn-sm" onClick={()=>props.setshowMadetry(false)}>Okay</button>
        </div>
      </div>
    </div>
  </div>
</div>
    </>)
}
export default MandatoryQuestion;