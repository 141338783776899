import { createStore, applyMiddleware } from "redux";
import rootReducer from "../redux/reducer";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: hardSet,
    
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const Store = createStore(persistedReducer, {});
export const persistor = persistStore(Store)