import React from "react";
import '../../css/loader/loader.scss'

const Loader = () => {

    return (<>
        <div className="overlay-wrapper overlay-white">
            <div className="text-box">
                <div className="loader" id="loader-7"></div>
                <p className="lead text-bold mb-2"> Loading...</p>
            </div>
        </div>
    </>)

}
export default Loader 