import React from "react";

const  NoQuestion =()=> {


    return(<>
    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", height:"100vh", alignItems:"center"}}>
        <p style={{fontWeight:"bold" , fontSize:"24px"}}> Questions Not Found</p>
        <p>This test does not have any questions assigned to it.</p>
    </div>
    </>)

}
export default NoQuestion 