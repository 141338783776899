import React, { useEffect, useState } from "react";
import api from "../../api";
import "./panel.scss";
import ErrorTemplate from "../../components/error_template";
import PanalLayout from "../templates/panel_layout";
import { connect } from "react-redux";
import IllegalMsg from "../../components/illegalmsg_popup";
import Loader from "../../components/loader";

const Panel = (props) => {
  const [testPanelData, setTestPanalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState({});
  const [errorFlag, setErrorFlag] = useState(false);
  const [testSubmitted, setTestSubmitted] = useState(false);
  const [overlayShow, setOverlayShow] = useState(false);
  const [overlayHelpMessage, setOverlayHelpMessage] = useState(
    <i className="fas fa-exclamation-triangle text-red"></i>
  );
  const [overlayMainMessage, setOverlayMainMessage] = useState("");
  const [illegalOpen, setillegalOpen] = useState(false);

  function setSessionHeaders() {
    let sessionKey = "";
    let accountdata = props.institute_data;
    let testdata = props.test_data;
    let studentdata = props.student_data;
    if (
      studentdata != undefined &&
      testdata != undefined &&
      accountdata != undefined
    ) {
      sessionKey =
        studentdata.userid +
        ":" +
        testdata.test_id +
        ":" +
        accountdata.account_id +
        ":" +
        props.session_key;
      sessionKey = btoa(sessionKey);

      return sessionKey;
    }
  }

  const begainTestApi = () => {
    let request = {
      url: `/v1/examPanel/beginTest`,
      headers: {
        "X-Device-Info": btoa(JSON.stringify(window.navigator.userAgent)),
        "X-Device-Key": localStorage.getItem("identityKey"),
        "X-Session-Key": setSessionHeaders(),
      },
    };
    api
      .getAuth(request)
      .then((response) => {
        if (response.data["validate"]) {
          let data = response.data["data"];
          setTestPanalData(data);
          if (data.languages != undefined) {
            props.dispatch({
              type: "CHANGE_LANGUAGE_QUESTION",
              test_language: data.languages,
            });
          }
          props.dispatch({ type: "QUESTIONS_DATA", ques_data: data.ques_data });
          props.dispatch({
            type: "TEST_SETTING",
            test_settings: data.test_settings,
          });
          if (data.subjects_data.length > 0) {
            props.dispatch({
              type: "SUBJECT_DATA",
              subjects_data: data.subjects_data,
            });
          }
          if (props.student_data.userid !== 1234567890) {
            if (props.test_data.test_type_id == 2) {
              if (data?.test_settings?.is_show_score == 1) {
                if (
                  data.ques_data.filter(
                    (el) => el.is_auto_evaluate == 0 && el.ques_type_id == 2
                  ).length < 1
                ) {
                  props.dispatch({
                    type: "SHOW_SCORECARD",
                    showscorecard: true,
                  });
                } else {
                  props.dispatch({
                    type: "SHOW_SCORECARD",
                    showscorecard: false,
                  });
                }
              } else {
                props.dispatch({
                  type: "SHOW_SCORECARD",
                  showscorecard: false,
                });
              }
            } else {
              if (
                data.ques_data.filter(
                  (el) => el.is_auto_evaluate == 0 && el.ques_type_id == 2
                ).length < 1
              ) {
                props.dispatch({ type: "SHOW_SCORECARD", showscorecard: true });
              } else {
                props.dispatch({
                  type: "SHOW_SCORECARD",
                  showscorecard: false,
                });
              }
            }
          }
          props.dispatch({
            type: "ATTEMPT_DATA",
            attempt_data: data.attempt_data.attempt_data,
          });
          if (
            !data.attempt_data.attempt_data == null ||
            Object.keys(data.attempt_data.attempt_data).length > 0
          ) {
            let status_arr = JSON.parse(
              data.attempt_data.attempt_data.answer_json
            ).question_state.split("#");
            let questions = JSON.parse(
              data.attempt_data.attempt_data.answer_json
            ).questions.split("#");
            let subjectData = JSON.parse(
              data.attempt_data.attempt_data.answer_json
            ).subjective_answer;
            props.dispatch({
              type: "SUBJECTIVE_ANSWERS",
              subjective_answer: [...subjectData],
            });
            if (status_arr.length > 0) {
              const questionStatus = [];
              questions.forEach((el, i) => {
                questionStatus.push({
                  ques_id: el,
                  status_number: parseInt(status_arr[i], 10),
                });
              });
              props.dispatch({
                type: "CHANGES_STATUS",
                questionstatus: questionStatus,
              });
            }
            let attempt_time = JSON.parse(
              data.attempt_data.attempt_data.answer_json
            ).attempt_time.split("#");
            props.dispatch({
              type: "ATTEMPT_TIME",
              attempt_time: attempt_time,
            });

            let answerJson = JSON.parse(
              data.attempt_data.attempt_data.answer_json
            ).answers.split("#");
            props.dispatch({ type: "SET_ANSWER", answers_json: answerJson });

            let bookMarked = JSON.parse(
              data.attempt_data.attempt_data.answer_json
            ).is_bookmark.split("#");
            props.dispatch({
              type: "SET_BOOKMARKED",
              is_bookmarked: bookMarked,
            });
          }

          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrorFlag(true);
        setShowError(err.response);
      });
  };

  const serverTimeStamp = async () => {
    let request = {
      url: `/v1/examPanel/time`,
      headers: {
        "X-Device-Info": btoa(JSON.stringify(window.navigator.userAgent)),
        "X-Device-Key": localStorage.getItem("identityKey"),
      },
    };
    await api.getAuth(request).then(
      (response) => {
        props.dispatch({
          type: "START_TIME",
          startTime: response.data.timestamp,
        });
      },
      (errors) => {}
    );
  };

  useEffect(() => {
    props.dispatch({ type: "ILLEGAL_COUNT", illegalCount: 0 });
    setLoading(true);
    setOverlayMainMessage("Please wait...");
    setOverlayHelpMessage("Initializing the platform");
    let is_test_completed_loc = sessionStorage.getItem("is_test_complete");
    if (is_test_completed_loc == 1) {
      setTestSubmitted(true);
      setLoading(false);
      setOverlayShow(true);
      setOverlayMainMessage("Test is submitted or expired");
      setOverlayHelpMessage("This attempt has already been submitted.");
    } else {
      begainTestApi();
      serverTimeStamp();
    }
  }, []);

  useEffect(() => {
    if (
      props?.illegalCount > 0 &&
      testPanelData &&
      testPanelData?.test_settings
    ) {
      if (
        props?.illegalCount > 0 &&
        props?.illegalCount <= testPanelData.test_settings.panel_leave_limit + 1
      ) {
        setillegalOpen(true);
      } else {
        // submitTestApi(props)
      }
    }
  }, [props?.illegalCount]);

  return (
    <>
      {loading && (
        <div>
          <Loader />
        </div>
      )}
      {illegalOpen === true && (
        <IllegalMsg
          illegalCount={props?.illegalCount}
          limit={testPanelData?.test_settings.panel_leave_limit + 1}
          setillegalOpen={setillegalOpen}
        />
      )}

      {loading && errorFlag == true && (
        <div className="overlay-wrapper overlay-white">
          <div className="text-box">
            <div className="loader" id="loader-7"></div>
            <p className="lead text-bold mb-2">{overlayMainMessage}</p>
            <p className="">{overlayMainMessage}</p>
          </div>
        </div>
      )}
      {loading == false &&
        errorFlag == true &&
        showError &&
        Object.keys(showError).length > 0 && (
          <ErrorTemplate showError={showError} />
        )}
      {!loading &&
        testSubmitted == false &&
        testPanelData &&
        Object.keys(testPanelData).length > 0 && (
          <div>
            <PanalLayout testPanelData={testPanelData} />
          </div>
        )}
      {(testSubmitted == true || props.testSubmitted === true) && (
        <div className="overlay-wrapper overlay-white">
          <div className="text-box">
            <p className="lead text-bold mb-2">
              <i className="far fa-smile"></i>&nbsp; Thank You{" "}
            </p>
            <p className="text-gray">Your exam has been submitted.</p>
          </div>
        </div>
      )}
      {overlayShow && (
        <div className="overlay-wrapper overlay-white">
          <div className="text-box">
            <p className="lead text-bold mb-2">{overlayMainMessage} </p>
            <p className="">{overlayHelpMessage}</p>
            <button className="btn btn-primary btn-sm mt-4 btn-close">
              {" "}
              Close{" "}
            </button>
          </div>
        </div>
      )}
      <div></div>
    </>
  );
};
const mapStateToProps = (state) => ({ ...state });
export default connect(mapStateToProps)(Panel);
