import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import App from './App';
import { Provider } from 'react-redux';
import {Store, persistor}  from './store';
import { PersistGate } from 'redux-persist/integration/react'

ReactDOM.render(<Provider store={Store}> <PersistGate  persistor={persistor}><App /></PersistGate></Provider>, document.getElementById('root'));

;
