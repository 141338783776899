import React, { useEffect } from "react"
import { Chart } from 'chart.js';
const SectionWise = (props) => {
    useEffect(() => {
        secWiseLine()
    }, [])
    const secWiseLine = () => {

        props.AdvReportData.topic_wise_comparison.forEach((topic, index) => {
            let linelabels = [];
            let linedata = [];
            var str = [];
            topic.tests_data.forEach((test, index) => {
                let test_name = '';
                if(test.test_name.length > 10)   
                {
                  test_name = test.test_name.substring(0, 10) + '...';
                } else {
                  test_name = test.test_name;
                }

                linelabels.push(test_name);
                linedata.push(test.marks_obtained / test.maximum_marks * 100);
                str.push(test.marks_obtained + ' / ' + test.maximum_marks);
            });
            var id = "secWiseLine-" + index;
            var nid = "no-secWiseLine-data-" + index;
            var canvas = document?.getElementById(id);
            var ctx = canvas?.getContext("2d");
            /* var ctx = document.getElementById("canvas-"+index).getContext("2d"); */
            var myLine = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: linelabels,
                    datasets: [{
                        label: topic.subject_name,
                        backgroundColor: '#3498db',
                        borderColor: '#3498db',
                        data: linedata,
                        fill: false,
                    }]
                },
                options: {
                    legend: {
                        display: false,
                    },
                    responsive: true,
                    tooltips: {
                        mode: 'label',
                        callbacks: {
                            title: function (t, d) {
                                return d.labels[t[0].index];
                            },
                            beforeFooter: function (tooltipItems, data) {
                                return str[tooltipItems[0].index];
                            },
                            footer: function (tooltipItems, data) {
                                return 'Marks Obtained';
                            }
                        }
                    },
                    hover: {
                        mode: 'nearest',
                        intersect: true
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Tests'
                            },
                            ticks: {
                                callback: function (value) {
                                    var str = value.substr(0, 10);
                                    return str + '...';//truncate
                                },
                            }
                        }],
                        yAxes: [{
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Marks Obtained (in %)'
                            },
                            ticks: {
                                beginAtZero: true,
                            }
                        }]
                    },
                    animation: {
                        onComplete: function (animation) {
                            var firstSet = animation.chart.config.data.datasets[0].data.length

                            if (firstSet < 2) {
                                document.getElementById(nid).style.display = 'flex';
                                document.getElementById(id).style.display = 'none'
                            } else {
                                document.getElementById(nid).style.display = 'none';
                            }
                        }
                    }
                }
            });

        });
    }
    return (<>
        <div className="row m-t-2e border-b-1" >
            {props.AdvReportData.topic_wise_comparison.length > 0 &&
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-b-1" >
                    <h3>Section wise (within the subject) Analysis of Previous and Present tests</h3>
                </div>
            }
            {
                props.AdvReportData.topic_wise_comparison.length == 0 &&
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex m-2e" >
                    <h5 className="m-auto">Previous Tests History is Not Available</h5>
                </div>}
            {
                props.AdvReportData.topic_wise_comparison && props.AdvReportData.topic_wise_comparison.map((item, index) => (
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 m-t-2e border-b-1 p-b-2e"
                    >
                        <h5 className="text-center f-w-600">{index+1} . {item.subject_name} :  {item.topic_name} </h5>
                        <canvas id={`secWiseLine-${index}`} height="100"></canvas>
                        <p className="text-center" id={`no-secWiseLine-data-${index}`} style={{height: "inherit"}}><span className="m-auto">Previous
                            Tests History is Not Available</span></p>
                        {/* <div *ngIf="topic_wise_comparison.length > 0">
        </div> */}
                    </div>
                ))
            }
        </div>
    </>)
}
export default SectionWise;
