import config from "../config";
import axios from "axios";
import URL from "./url";

const api = {
    get: (requestData) => axios.get(requestData),
    getAuth: (requestData) => axios.get(
        URL[config.env].examdeskApiUrl+requestData.url, 
        {
            headers: requestData.headers
        }
        
    ),
    getAuthresult: (requestData) => axios.get(
        URL[config.env].apiUrl+requestData.url, 
        {
            headers: requestData.headers
        }
        
    ),
    post: (requestData) => axios.post(
        URL[config.env].examdeskApiUrl+requestData.url, 
        requestData.data
    ),
    postAuthHeader: (requestData) => axios.post(
        URL[config.env].examdeskApiUrl+requestData.url, 
        requestData.post, {...requestData.headers}
    ),
    postAuth: (requestData) => axios.post(
        URL[config.env].examdeskApiUrl+requestData.url, 
        requestData.data, 
        {
            headers: requestData.headers
        }
    ),

    put: (requestData)=> axios.put(
       requestData.url,
        requestData.data,
        {
            headers: requestData.headers  
        }
    ),


   
   
    
}


export default api