import React from "react";
import { connect } from "react-redux";
import notvisited from "../../../assets/icons/not-visited.png"
import notanswered from "../../../assets/icons/not-answered.png"
import answered from "../../../assets/icons/answered.png"
import notanswerreview from "../../../assets/icons/not-answered-review.png"
import answerreview from "../../../assets/icons/answered-review.png"

const Legends = (props) => {
    return (<>
        {
            props.questionstatus && <div className="ledends">
                <div className="firstlegends">
                    <div style={{ width: "155px" }}>
                        <img className="legendsImg" src={notvisited} />
                        <span className="legendstext">Not Visited</span>
                        <div className="legend-value">
                            <span>{props.questionstatus.filter(({ status_number }) => status_number == 1).length}</span>
                        </div>
                    </div>
                    <div style={{ width: "155px" }}>
                        <img className="legendsImg" src={notanswered} />
                        <span className="legendstext">Not Answered</span>
                        <div className="legend-value">
                            <span>{props.questionstatus.filter(({ status_number }) => status_number == 2).length}</span>
                        </div>
                    </div>
                </div>
                <div className="firstlegends">
                    <div style={{ width: "155px" }}>
                        <img className="legendsImg" src={answered} />
                        <span className="legendstext">Answered</span>
                        <div className="legend-value"><span>{props.questionstatus.filter(({ status_number }) => status_number == 4).length}</span></div>
                    </div>
                    <div style={{ width: "155px" }}>
                        <img className="legendsImg" src={notanswerreview} />
                        <span className="legendstext">Marked for Review</span>
                        <div className="legend-value" ><span>{props.questionstatus.filter(({ status_number }) => status_number == 3).length}</span></div>
                    </div>
                </div>
                <div style={{ marginTop: "10px" }} >
                    <img className="legendsImg" src={answerreview} />
                    <span className="legendstext">Answered & Marked for Review</span>
                    <div className="legend-value" ><span>{props.questionstatus.filter(({ status_number }) => status_number == 5).length}</span></div>
                </div>
            </div>}
    </>)
}
const mapStateToProps = state => ({ state, questionstatus: state.questionstatus })
export default connect(mapStateToProps)(Legends)
