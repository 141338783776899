import React, { useEffect, useState } from "react";
import './guestscore.css'
import Logo from "../../assets/images/logo_icon.png";
import el_user from "../../assets/icons/el_user.svg";
import SubjectWiseReport from "./subjectWiseReport";
import QuestionWiseReport from "./QuestionWiseReport";
import Symmary from "./Summary";
import { connect } from "react-redux";


const GuestScore = (props) => {
    const [selectReport, setSelectReport] = useState(0);
    const [testReport, setTestReport] = useState({})
    const [topperreport, setTopperReport] = useState({})
    const [testData, setTestData]= useState({})
    const [studentData, setStudentData]= useState({})
    const [questionData, setQuestionData]= useState([])

   const ans_pool=[];
   const ans_stats = {
      correct_ans: 0,
      incorrect_ans: 0,
      not_attempted_ans: 0,
      marks_obtained: 0,
      correct_marks: 0,
      incorrect_ans_marks: 0,
      not_attempted_marks: 0,
      total_time_taken: 0,
      string_time_taken: ''
    } 
    const calcPercent = (total_score, total_marks) => {
        return ((((total_score / total_marks)) * 100).toFixed(2));

        /*  return (((this.summary.total_score / this.summary.total_marks)) * 100).toFixed(2); */
    }

const  getAnswerString=(que)=> {
    var ans = [];
    if (que.ques_type_id == 2) { //Subjective
      return que.answer;
    }
    else {

      for (let i = 0; i < que.options.length; i++) {
        let option = que.options[i];
        switch (que.ques_type_id) {
          case 1: //Single Choice
          case 3: //Multi choice
            if (option.is_correct) {
              ans.push(String.fromCharCode(option.option_no + 96));
            }
            break;
          case 5: //True False
            if (option.is_correct) {
              ans.push('a');
            }
            else {
              ans.push('b');
            }
            break;
          case 6: //Match Matrix
          case 7: //Match Following
            if (option.is_correct)   // 4 = e, 5 = a
            {
              let choice_options = option.correct_ans.split('|');
              choice_options.forEach((el, key) => {
                choice_options[key] = String.fromCharCode(parseInt(el) + 96);
              });

              ans.push(option.option_no + '=' + choice_options.join('|'));
            }
            break;
          default:
            ans.push('-1');
        }
      }
      return ans.join(',');
    }
  }

  const format_user_ans=()=> {
    let questions = this.user_answers.questions.split('#');
    let answers = this.user_answers.answers.split('#');
    let attempt_time = this.user_answers.attempt_time.split('#');
    let question_state = this.user_answers.question_state.split('#');
    let subjective_answer = this.user_answers.subjective_answer;

    sessionStorage.setItem('user-answers', JSON.stringify(answers));
    questions.map((que_id, key) => {
      let ans = -1;
      if (/^(subjective_[0-9]*)$/.test(answers[key])) {
        let index = answers[key].split('_')[1];
        ans = subjective_answer[index];
      }
      else {
        ans = answers[key];
      }
      this.ans_pool[que_id] = {
        answer: ans,
        time_taken: attempt_time[key],
        que_state: question_state[key]
      }
    });
    sessionStorage.setItem('questions-list', JSON.stringify(this.user_answers.questions));
    sessionStorage.setItem('attempted-time', JSON.stringify(this.user_answers.attempt_time));

  }

  const  evaluate=(questions)=> {
    questions.forEach(que => {

      let correct_answer = this.getAnswerString(que);
      que.correct_answer = correct_answer;
      let user_answer = this.ans_pool[que.ques_id].answer;
      let que_time = parseInt(this.ans_pool[que.ques_id].time_taken);

      this.ans_stats.total_time_taken += que_time;
      if (user_answer == '-1') {
        this.ans_stats.not_attempted_ans++;
        this.ans_stats.not_attempted_marks -= parseFloat(que.not_attempt_marks);
      }
      else if (correct_answer == user_answer) {
        this.ans_stats.correct_ans++;
        this.ans_stats.correct_marks += parseFloat(que.positive_marks);
      }
      else if(correct_answer != user_answer && user_answer != -1){
        this.ans_stats.incorrect_ans++;
        this.ans_stats.incorrect_ans_marks -= parseFloat(que.negative_marks);
      }
    });

    let time_taken = Math.ceil((this.ans_stats.total_time_taken) / 1000)

    this.ans_stats.string_time_taken = Math.floor(time_taken / 3600)
      + ' : ' + ("0" + (Math.floor(time_taken / 60) % 60)).slice(-2)
      + ' : ' + ("0" + time_taken % 60).slice(-2);

    this.ans_stats.marks_obtained = parseFloat(this.ans_stats.correct_marks)-
      - parseFloat(this.ans_stats.incorrect_ans_marks)
      - parseFloat(this.ans_stats.not_attempted_marks);
  }



 useEffect(()=>{
    setTestData(props.test_data)
    setStudentData(props.student_data)
 },[])

    return (<>
        <div className="container">
            <div className="headerdiv">
                <div style={{ width: "15%" }}>
                    <img height="80px" width="80px" src={Logo} />
                </div>
                <div style={{ width: "70%", alignItems: "center", justifyContent: "center", display: "flex" }}>
                    <span style={{ fontWeight: "bold", fontSize: "25px" }}>{testData.test_name} </span>
                </div>
                <div style={{ width: "15%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img height="60px" width="60px" src={studentData.photo ? studentData.photo : el_user} />
                    <span style={{ fontSize: "20px" }}>{studentData.student_name}</span>
                </div>
            </div>
            <div className="reportcontainer">
                <div className="reportmaindiv">
                    <div className="testdetail">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <span>
                                Test Date:
                            </span>
                            <span style={{ width: "45%" }}>
                                <strong>22 may 2022</strong>
                            </span>

                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <span>
                                Start Time:
                            </span>
                            <span style={{ width: "45%" }}>
                                <strong>9:30:10</strong>
                            </span>

                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <span>
                                End Time:
                            </span>

                            <span style={{ width: "45%" }}>
                                <strong>9:35:52</strong>
                            </span>



                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <span>
                                Rank:
                            </span>
                            <span style={{ width: "45%" }}>
                                <strong>{testReport.rank}</strong>
                            </span>

                        </div>
                    </div>
                    <div className="markdetails">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <span>
                                Total Questions:
                            </span>
                            <span style={{ width: "45%" }}>
                                <strong>{testReport.right_ques_count + testReport.wrong_ques_count + testReport.left_ques_count}</strong>
                            </span>

                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <span>
                                Marks Obtained:
                            </span>
                            <span style={{ width: "45%" }}>
                                <strong>{testReport.total_score} / {testReport.total_marks}</strong>
                            </span>

                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <span>
                                Time Taken:
                            </span>

                            <span style={{ width: "45%" }}>
                                <strong>35:52</strong>
                            </span>



                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <span>
                                Percentage:
                            </span>
                            <span style={{ width: "45%" }}>
                                <strong>{calcPercent(testReport.total_score, testReport.total_marks)} %</strong>
                            </span>

                        </div>

                    </div>

                </div>

            </div>
            <div className="reportcontainer">
                <div className="reportmaindiv">
                    <span className={selectReport !== 0 ? "buttonStyle" : "onClickButton"} onClick={() => setSelectReport(0)}>
                        Summary
                    </span>
                    <span className={selectReport !== 1 ? "buttonStylesubject" : "onClickButtonsubjeact"} onClick={() => setSelectReport(1)}>
                        Subject Wise
                    </span>
                    <span className={selectReport !== 2 ? "buttonStyle" : "onClickButton"} onClick={() => setSelectReport(2)}>
                        Questions Wise
                    </span>

                </div>
            </div>
            <div className="reportcontainer">
                {selectReport === 1 ? <SubjectWiseReport/> : (selectReport == 2 && <QuestionWiseReport />)}
            </div>
            <div>
                {selectReport === 0 && <div className="reportcontainer">
                    <Symmary />
                </div>}
            </div>
        </div>


    </>)

}

const mapStateToProps = state => ({ ...state })
export default connect(mapStateToProps)(GuestScore)