import React from "react";
import './hindi.scss'
import notvisited from "../../../assets/icons/not-visited.png"
import notanswered from "../../../assets/icons/not-answered.png"
import answered from "../../../assets/icons/answered.png"
import notanswerreview from "../../../assets/icons/not-answered-review.png"
import answerreview from "../../../assets/icons/answered-review.png"

const HindiInstraction = (props) => {
  return (<>
    <div className="col-md-12 test-instructions">
      <p className="msgTitle">परीक्षण निर्देश</p>
      <div >{props.instruction}</div>
    </div>

    <div className="col-md-12 general-instructions">
      <p className="msgTitle">सामान्य अनुदेश:</p>
      <section>
        <ol>
          <li className="msgFont">सर्वर पर घड़ी लगाई गई है तथा आपकी स्क्रीन के दाहिने कोने में शीर्ष पर काउंटडाउन टाइमर में आपके लिए परीक्षा समाप्त करने के लिए शेष समय प्रदर्शित होगा। परीक्षा समय समाप्त होने पर, आपको अपनी परीक्षा बंद या जमा करने की जरूरत नहीं है । यह स्वतः बंद या जमा हो जाएगी।</li>
          <li className="msgFont">स्क्रीन के दाहिने कोने पर प्रश्न पैलेट, प्रत्येक प्रश्न के लिए निम्न में से कोई एक स्थिति प्रकट करता है:
            <ul className="legends-listing" type="none">
              <li className="msgFont"><img src={notvisited} /> <span>आप अभी तक प्रश्न पर नहीं गए हैं। </span></li>
              <li className="msgFont"><img src={notanswered} /><span> आपने प्रश्न का उत्तर नहीं दिया है।</span></li>
              <li className="msgFont"><img src={answered} /> आप प्रश्न का उत्तर दे चुके हैं।</li>
              <li className="msgFont"><img src={notanswerreview} /> आपने प्रश्न का उत्तर नहीं दिया है पर प्रश्न को पुनर्विचार के लिए चिन्हित किया है।</li>
              <li className="msgFont"><img src={answerreview} /> प्रश्न जिसका उत्तर दिया गया है और समीक्षा के लिए भी चिन्हित है , उसका मूल्यांकन किया जायेगा ।</li>
            </ul>
          </li>
          <li className="msgFont">कृप्या ध्यान दे प्रश्न "समीक्षा के लिए चिह्नित" की जांच नहीं की जाएगी, इसलिए अंक आवंटित नहीं किए जाएंगे।</li>
          <li className="msgFont">आप प्रश्न पैलेट को छुपाने के लिए  चिन्ह पर क्लिक कर सकते है, जो प्रश्न पैलेट के बाईं ओर दिखाई देता है, जिससे प्रश्न विंडो सामने आ जाएगा. प्रश्न पैलेट को फिर से देखने के लिए,  चिन्ह पर क्लिक कीजिए जो प्रश्न विंडो के दाईं ओर दिखाई देता है।</li>
          <li className="msgFont">सम्पूर्ण प्रश्नपत्र की भाषा को परिवर्तित करने के लिए आपको अपने स्क्रीन के ऊपरी दाहिने सिरे पर स्थित प्रोफाइल इमेज पर क्लिक करना होगा। प्रोफाइल इमेज को क्लिक करने पर आपको प्रश्न के अंतर्वस्तु को इच्छित भाषा में परिवर्तित करने के लिए ड्राप-डाउन मिलेगा ।</li>
          <li className="msgFont">आपको अपने स्क्रीन के निचले हिस्से को स्क्रॉलिंग के बिना नेविगेट करने के लिए  और ऊपरी हिस्से को नेविगेट करने के लिए  पर क्लिक करना होगा ।</li>
        </ol>
      </section>

      <section>
        <p className="msgFont"><b>किसी प्रश्न पर जाना :</b></p>
        <ol start="7">
          <li className="msgFont">उत्तर देने हेतु कोई प्रश्न चुनने के लिए, आप निम्न में से कोई एक कार्य कर सकते हैं:
            <ol type="a">
              <li className="msgFont">स्क्रीन के दाईं ओर प्रश्न पैलेट में प्रश्न पर सीधे जाने के लिए प्रश्न संख्या पर क्लिक करें। ध्यान दें कि इस विकल्प का प्रयोग करने से मौजूदा प्रश्न के लिए आपका उत्तर सुरक्षित नहीं होता है।</li>
              <li className="msgFont">वर्तमान प्रश्न का उत्तर सुरक्षित करने के लिए और क्रम में अगले प्रश्न पर जाने के लिए Save & Next पर क्लिक करें।</li>
              <li className="msgFont">वर्तमान प्रश्न का उत्तर सुरक्षित करने के लिए, पुनर्विचार के लिए चिह्नित करने और क्रम में अगले प्रश्न पर जाने के लिए Mark for Review & Next पर क्लिक करें।</li>
            </ol>
          </li>
        </ol>
      </section>

      <section>
        <p className="msgFont"><b>बहुविकल्पीय प्रकार के प्रश्न के लिए</b></p>
        <ol start="8">
          <li className="msgFont">बहुविकल्पीय प्रकार के प्रश्न के लिए
            <ol type="a">
              <li className="msgFont">अपना उत्तर चुनने के लिए, विकल्प के बटनों में से किसी एक पर क्लिक करें।</li>
              <li className="msgFont">चयनित उत्तर को अचयनित करने के लिए, चयनित विकल्प पर दुबारा क्लिक करें या Clear Response बटन पर क्लिक करें।</li>
              <li className="msgFont">अपना उत्तर बदलने के लिए, अन्य वांछित विकल्प बटन पर क्लिक करें।</li>

              <li className="msgFont">अपना उत्तर सुरक्षित करने के लिए, आपको Save & Next पर क्लिक करना जरूरी है।</li>
              <li className="msgFont">किसी प्रश्न को पुनर्विचार के लिए चिह्नित करने हेतु Mark for Review & Next बटन पर क्लिक करें।</li>
            </ol>
          </li>
          <li className="msgFont">किसी प्रश्न का उत्तर बदलने के लिए, पहले प्रश्न का चयन करें, फिर नए उत्तर के विकल्प पर क्लिक करने के बाद Save & Next बटन पर क्लिक करें।</li>
        </ol>
      </section>

      <section>
        <p className="msgFont"><b>अनुभागों द्वारा प्रश्न पर जाना:</b></p>
        <ol start="10">
          <li className="msgFont">इस प्रश्नपत्र में स्क्रीन के शीर्ष बार पर अनुभाग (Sections) प्रदर्शित हैं। किसी अनुभाग के प्रश्न, उस अनुभाग के नाम पर क्लिक करके देखे जा सकते हैं। आप वर्तमान में जिस अनुभाग का उत्तर दे रहे हैं, वह अनुभाग हाईलाइट होगा।</li>
          <li className="msgFont">किसी अनुभाग के लिए अंतिम प्रश्न के Save & Next बटन पर क्लिक करने के बाद, आप स्वचालित रूप से अगले अनुभाग के प्रथम प्रश्न पर पहुंच जाएंगे।</li>
          <li className="msgFont">आप परीक्षा में निर्धारित समय के दौरान किसी भी समय प्रश्नावलियों और प्रश्नों के बीच अपनी सुविधा के अनुसार आ-जा (शफल कर) सकते हैं।</li>
          <li className="msgFont">परीक्षार्थी संबंधित सेक्शन की समीक्षा को लीजेन्ड के भाग के रूप में देख सकते हैं ।</li>
        </ol>
      </section>
    </div>

  </>)
}
export default HindiInstraction