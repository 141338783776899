// import "./styles.css";
import DonutChart from "react-donut-chart";
import React from "react";


export default function Symmary() {
    const reactDonutChartdata = [
        {
            label: "NDC",
            value: 25,
            color: "red"
        },
        {
            label: "RDC",
            value: 65,
            color: "#FEB019"
        },
        {
            label: "STOCKIST",
            value: 100,
            color: "#FF4560"
        },
        {
            label: "HOSPITAL",
            value: 15,
            color: "#775DD0"
        }
    ];
    const reactDonutChartBackgroundColor = [
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0"
    ];
    const reactDonutChartInnerRadius = 0.5;
    const reactDonutChartSelectedOffset = 0.04;
    const reactDonutChartHandleClick = (item, toggled) => {
        if (toggled) {
            console.log(item);
        }
    };
    let reactDonutChartStrokeColor = "#FFFFFF";
    const reactDonutChartOnMouseEnter = (item) => {
        let color = reactDonutChartdata.find((q) => q.label === item.label).color;
        reactDonutChartStrokeColor = color;
    };

    return (<>
        <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", width: "80%" }}>
        <div style={{display:"flex" , flexDirection:"column"}}>
                <span>Answer Based Analysis</span>
                <DonutChart
                    width={300}
                    onMouseEnter={(item) => reactDonutChartOnMouseEnter(item)}
                    strokeColor={reactDonutChartStrokeColor}
                    data={reactDonutChartdata}
                    colors={reactDonutChartBackgroundColor}
                    innerRadius={reactDonutChartInnerRadius}
                    selectedOffset={reactDonutChartSelectedOffset}
                    onClick={(item, toggled) => reactDonutChartHandleClick(item, toggled)}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span>Subject Based Analysis</span>
                <DonutChart
                    width={300}
                    onMouseEnter={(item) => reactDonutChartOnMouseEnter(item)}
                    strokeColor={reactDonutChartStrokeColor}
                    data={reactDonutChartdata}
                    colors={reactDonutChartBackgroundColor}
                    innerRadius={reactDonutChartInnerRadius}
                    selectedOffset={reactDonutChartSelectedOffset}
                    onClick={(item, toggled) => reactDonutChartHandleClick(item, toggled)}
                />
            </div>
        </div>
    </>);
}
