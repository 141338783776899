import React, { useEffect, useState } from "react";
import Calculator from "../../../components/calculator";
import "./panel_layout.css";
import InstructionPopUp from "../../../components/instructions/instruction_popup";
import { connect } from "react-redux";
import QuestionPanel from "../question_panel";
import StudentDetails from "../student_details";
import menuIcon from "../../../assets/icons/menu.png";
import menu2 from "../../../assets/icons/menu2.png";
import useWindowDimensions from "../../../services/useWindowDimensions";
import Legends from "./legends";
import saveAndNext from "../../../services/saveAndNext";
import Confirm from "../../../components/confirm";
import { submitTestApi } from "../../../services/saveAndNext";
import Subjectwiselegendsmain from "../subjectwiselegendsmailn";
import calculatorIncon from "../../../assets/icons/calculatorIcan.png";
import instructionIcon from "../../../assets/icons/instructionIncon.png";
import Timer from "../Timer";
import MandatoryQuestion from "../../../components/mandatory_question_popup";

const PanelLayout = (props) => {
  const [showCalculater, setShowCalculater] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);
  const [languageSelected, setLanguageSelected] = useState(1);
  const [ques_data, setQues_data] = useState([]);
  const [groupQues, setgroupQues] = useState([]);
  const [activeQuesData, setactiveQuesData] = useState({});
  const [showDrop, setShowDrop] = useState(true);
  const [drower, setDrower] = useState(true);
  const [show, setShow] = useState(false);
  const [showMadetry, setshowMadetry] = useState(false);
  const { width } = useWindowDimensions();

  let ques_dataloc;
  let seq = [];

  const fetchQuestionsBasedOnLanguage = async (questions) => {
    if (questions) {
      let language = questions.find((obj) => obj.id == languageSelected);
      if (language && Object.keys(language).length > 0) {
        ques_dataloc = language.ques_data;
        setQues_data(ques_dataloc);
      }
    } else {
      ques_dataloc = props.state.ques_data;
      setQues_data(ques_dataloc);
    }
  };

  const setup_panel = () => {
    props.dispatch({ type: "ACTIVE_QUESTION_NUMBER", activeQuesInd: 0 });
    setactiveQuesData(ques_dataloc[0]);
    saveAndNext(props);
  };

  const submitApi = () => {
    submitTestApi(props);
  };
  useEffect(async () => {
    if (
      props.state.ques_data == undefined ||
      props.state.test_settings == undefined ||
      props.state.subjects_data == undefined
    ) {
      window.location.replace("/");
    } else {
      if (props.test_data.is_multi_lingual == 1) {
        let questionList = props.state.languages;
        fetchQuestionsBasedOnLanguage(questionList);
      } else {
        setQues_data(props.state.ques_data);
        ques_dataloc = props.state.ques_data;
      }
      if (ques_dataloc.length === 0) {
        window.location.replace("/noQuestion");
      }
      if (ques_dataloc.length > 0) {
        if (props.state.test_settings.is_grouping_subject == 1) {
          if (props?.state?.test_data?.is_neet_test == 1) {
            let groupQuesloc = props.state.subjects_data;
            groupQuesloc.forEach((obj) => {
              obj.questions = [];
            });
            let subjectIds = props.state.subjects_data.map((obj) => {
              return "" + obj.subject_id + "#" + obj.section_id;
            });
            groupQuesloc.forEach((obj, index) => {
              if (
                subjectIds.indexOf("" + obj.subject_id + "#" + obj.section_id) >
                -1
              ) {
                obj.questions = ques_dataloc.filter(
                  (ob) =>
                    "" + obj.subject_id + "#" + obj.section_id ==
                    "" + ob.subject_id + "#" + ob.section_id
                );
                obj.questions.map((item, index) => {
                  seq.push(item);
                });
                setQues_data(seq);
              }
            });
            setgroupQues(groupQuesloc);
          } else {
            let groupQuesloc = props.state.subjects_data;
            groupQuesloc.forEach((obj) => {
              obj.questions = [];
            });
            let subjectIds = props.state.subjects_data.map((obj) => {
              return obj.subject_id;
            });
            groupQuesloc.forEach((obj, index) => {
              if (subjectIds.indexOf(obj.subject_id) > -1) {
                obj.questions = ques_dataloc.filter(
                  (ob) => ob.subject_id == obj.subject_id
                );
                obj.questions.map((item, index) => {
                  seq.push(item);
                });
                setQues_data(seq);
              }
            });
            setgroupQues(groupQuesloc);
          }
        } else {
          seq = ques_dataloc;
          setQues_data(ques_dataloc);
        }
        if (props.state.attempt_data == null && seq.length > 0) {
          let subnum = 0;
          let answer_json = [];
          let is_bookmarked = [];
          let subjective_answer = [];
          const questionStatus = [];
          const arr = [];
          seq.forEach((item, index) => {
            questionStatus.push({
              ques_id: item.ques_id,
              status_number: index == 0 ? 2 : 1,
            });
            is_bookmarked.push(0);
            arr.push(0);
            if (item.ques_type_id == 2) {
              answer_json.push(`subjective_${subnum}`);
              subjective_answer[subnum] = "-1";
              subnum++;
            } else {
              answer_json.push("-1");
            }
          });
          props.dispatch({
            type: "SET_BOOKMARKED",
            is_bookmarked: [...is_bookmarked],
          });
          props.dispatch({
            type: "SET_ANSWER",
            answers_json: [...answer_json],
          });
          props.dispatch({
            type: "SUBJECTIVE_ANSWERS",
            subjective_answer: [...subjective_answer],
          });
          props.dispatch({
            type: "CHANGES_STATUS",
            questionstatus: questionStatus,
          });
          props.dispatch({ type: "ATTEMPT_TIME", attempt_time: arr });
        }
        await setup_panel();
      }
    }
  }, []);

  useEffect(() => {
    if (ques_data.length > 0 && props.activeQuesInd >= 0) {
      let ques_dataloc = ques_data;
      setactiveQuesData(ques_dataloc[props.activeQuesInd]);
    }
    if (
      props.state.questionstatus.length > 0 &&
      ques_data.length > 0 &&
      props.activeQuesInd > 0
    ) {
      let questionStatusloc = props.state.questionstatus;
      if (questionStatusloc[props.activeQuesInd].status_number == 1) {
        questionStatusloc[props.activeQuesInd].status_number = 2;
        props.dispatch({
          type: "CHANGES_STATUS",
          questionstatus: [...questionStatusloc],
        });
      }
    }
  }, [props.activeQuesInd, ques_data]);

  useEffect(() => {
    var questionList = props.state.test_language;
    if (questionList !== undefined) {
      fetchQuestionsBasedOnLanguage(questionList);
    }
  }, [languageSelected]);

  const changeLanguage = (e) => {
    setLanguageSelected(e.target.value);
    props.dispatch({
      type: "CHANGES_LANGUAGE",
      languageSelected: e.target.value,
    });
  };
  const onClickDrop = () => {
    setShowDrop(!showDrop);
  };

  const onSubmit = () => {
    if (props?.state?.test_settings?.is_all_mandatory_questions == 1) {
      if (props?.state?.answers_json?.includes("-1")) {
        setshowMadetry(true);
      } else {
        setShow(true);
      }
    } else {
      setShow(true);
    }
  };

  return (
    <>
      {showCalculater && <Calculator />}
      {showInstruction && (
        <InstructionPopUp setShowInstruction={setShowInstruction} />
      )}
      {show && (
        <Confirm
          is_test_submit_status={
            props.state.test_settings.is_test_submit_status
          }
          setShow={setShow}
          submitApi={submitApi}
        />
      )}
      {showMadetry && <MandatoryQuestion setshowMadetry={setshowMadetry} />}
      <div className="maincontainer">
        <div className="headerpanel">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div className="logoImg">
              <button className="logobutton">Online test</button>
            </div>
            <div className="stateLine"></div>
            <div className="subjectTypeDiv">
              <span
                className="subjectType"
                title={props.state.test_data.test_name}
              >
                {" "}
                Test Name: {props.state.test_data.test_name}
              </span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <Timer />
            </div>
            <button
              className="showcalculator"
              style={{
                display: props.state.test_settings.is_calculator_available
                  ? "flex"
                  : "none",
              }}
              onClick={() => setShowCalculater(!showCalculater)}
            >
              <img
                style={{
                  height: "16px",
                  width: "14px",
                  marginLeft: "10px",
                  marginRight: "8px",
                }}
                src={calculatorIncon}
              />
              <span className="buttontext" style={{ color: "#0097F9" }}>
                {showCalculater ? "Hide Calculator" : "Show Calculator"}{" "}
              </span>
            </button>

            <button
              className="showInstruction"
              onClick={() => {
                setShowInstruction(!showInstruction);
              }}
            >
              <img
                style={{
                  height: "16px",
                  width: "14px",
                  marginLeft: "10px",
                  marginRight: "8px",
                }}
                src={instructionIcon}
              />
              <span className="buttontext" style={{ color: "#0097F9" }}>
                Show Instruction
              </span>
            </button>
            <button
              className="dropbutton"
              style={{ background: "none", outline: "none" }}
              onClick={() => onClickDrop()}
            >
              {showDrop == true ? (
                <img width="20px" height="20px" src={menuIcon} />
              ) : (
                <img width="20px" height="20px" src={menu2} />
              )}
            </button>
          </div>
        </div>
        <div className="mainbody">
          {Object.keys(activeQuesData).length > 0 && activeQuesData && (
            <QuestionPanel
              drower={drower}
              showDrop={showDrop}
              testPanelData={props.testPanelData}
              activeQuesData={activeQuesData}
              ques_data={ques_data}
              changeLanguage={changeLanguage}
            />
          )}

          <div
            className={
              showDrop == true
                ? drower === true
                  ? "informationfield"
                  : "widthSize"
                : width > 870
                ? drower === true
                  ? "informationfield"
                  : "widthSize"
                : "hideField"
            }
          >
            <div className="drower" onClick={() => setDrower(false)}>
              <div className="right"></div>
            </div>
            {drower === false && (
              <div className="drower" onClick={() => setDrower(true)}>
                <div className="left"></div>
              </div>
            )}
            <div className="studentDetailsblock">
              <div style={{ display: drower === false && "none" }}>
                <StudentDetails />
              </div>
              <div
                className={drower === false ? "dropbutton" : "testcountnumber"}
              >
                {(groupQues.length > 0 || ques_data.length > 0) && (
                  <Subjectwiselegendsmain
                    groupQues={groupQues}
                    ques_data={ques_data}
                  />
                )}
              </div>
              <div className={drower === false ? "dropbutton" : "legendsmain"}>
                <span style={{ fontWeight: "bold" }}>Legends</span>
                <Legends />
              </div>
            </div>
            <button
              className="submitbutton"
              style={{ display: drower === false && "none" }}
              onClick={() => onSubmit()}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  state,
  institute_data: state.institute_data,
  test_data: state.test_data,
  student_data: state.student_data,
  questionstatus: state.questionstatus,
  activeQuesInd: state.activeQuesInd,
});
export default connect(mapStateToProps)(PanelLayout);
