import React from 'react'
import './error_template.scss'

const ErrorTemplate = (props)=>{
    return(<>
 { 
 props.showError &&
   <div className="row text-center welcome-box">
    <div className="center-box m-auto pl-2 pr-2">
        <p className="lead mb-1 text-bold">{props.showError.status}! {props.showError.statusText}</p>
        <p className="text-small text-gray">{props.showError.data.errors.message}</p>
    </div>
</div>}
    </>)
}
export default ErrorTemplate;