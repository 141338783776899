import react, { useEffect, useState } from "react"
import "./media.scss"

const Media = (props) => {
  const [media, setMediaData] = useState([])
  function generateMediaHTML(data) {
    if (data == '' || data == null) {
      return '';
    }

    try {
      data = JSON.parse(data);
    }
    catch (e) {
      return '';
    }

    data.forEach(
      (media, key) => {
        try {
          data[key] = JSON.parse(media);
        }
        catch (e) {
          return;
        }
      }
    );

    return data;
  }

  useEffect(() => {
    setMediaData(generateMediaHTML(props.media))

  }, [])
  return (<>
    <div>
      <div  >
        {
          media.map((item, index) => (

            <div className="cardbox">

              {/*  YouTube Video Container */}

              {
                item.media_origin == 'youtube'&&

                <div >
                 <iframe  style={{height:"275px", width:"100%"}} src={item.source_path} frameborder="0" allowfullscreen></iframe>
                </div>}

              {/*  Uploaded/Other Video Container  */}
              {
                (item.media_origin !== 'youtube') && (item.media_type === 'video') &&
                <div>
                  <video className="media"controls disablePictureInPicture controlsList="nodownload">
                    <source src={item.source_path} type={item.source_type} />
                    Your browser does not support the video tag.
                  </video>
                </div>}

              {/* <!-- Uploaded/Other Audio Container --> */}
              {
                (item.media_origin != 'youtube') && (item.media_type == 'audio')&&
                <div>
                  <audio className="media" controls controlsList="nodownload">
                    <source src={item.source_path} type={item.source_type} />
                    Your browser does not support the audio element
                  </audio>
                </div>
              }

            </div>
          ))
        }

      </div>
    </div>


  </>)
}
export default Media