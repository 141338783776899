import React from "react"
import SubjectWiseLegendsCount from "../subjectwiselegends";
import "./subjectwiselegends.css"
class Subjectwiselegendsmain extends React.Component {
     quesIndex = 0;
    QuestionCount = () => {
       this.quesIndex = this.quesIndex + 1
   }
    constructor(props) {
        super();    
    }
    shouldComponentUpdate(nextProps) {
        if (nextProps.groupQues !== this.props.groupQues) {
            return true;
        } else {
            return false;
        }
    }
    render() {
        return (<>
            {
                this.props.groupQues.length>0 ? this.props.groupQues.map((itemdata, index1) => (
                    <div key={index1+"groupQues"}>
                        <div className="subjectTitleDiv"><span className="subjectTitle">Subject: </span> <p className="subjectName"> {itemdata.subject_name} {itemdata.hasOwnProperty('section_title') && " - " }  {itemdata?.section_title}</p> </div>

                        <div className="testCountNumberDiv">
                            {
                                itemdata.questions.map((item, index) => (<>
                                    {
                                        this.QuestionCount()
                                    }
                                    <SubjectWiseLegendsCount key={index+"itemdata"} index = {index} item={item}  quesIndex={this.quesIndex} />

                                </>))
                            }

                        </div>
                    </div>

                )) :
                
                <div className="testCountNumberDiv">
                            {
                                this.props.ques_data.map((item, index) => (<>
                                    {
                                        this.QuestionCount()
                                    }
                                    <SubjectWiseLegendsCount key={index+"itemdata"} index = {index} item={item}  quesIndex={this.quesIndex} />

                                </>))
                            }

                        </div>
            }
           


        </>)
    }
}

export default Subjectwiselegendsmain