import React, { useEffect } from "react";
// import { Chart } from 'chart.js';
import { Chart, registerables } from 'chart.js'
import GaugeChart from "react-gauge-chart";
import Gauge from "react-svg-gauge";

Chart.register(...registerables)
const TimeManagementAanalysis = (props) => {

    function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(2);
        let final = minutes + "." + seconds;
        return(parseFloat(final))
      }

    const timeMgmntPi = () => {
        
        let labels = [];
        let data = [];
        props.AdvReportData.subject_wise_analysis.forEach(subject => {
            labels.push(subject.subject_name);
            data.push(parseInt(subject.subject_questions.questions_answered));
        });
       
        var canvas = document?.getElementById("timeMgmntPi");
        var ctx = canvas?.getContext("2d");
        /* var ctx = document.getElementById('myChart1').getContext('2d'); */
        var myBarChart = new Chart(ctx, {
            type: 'pie',
            data: {
                labels: labels,
                datasets: [{
                    backgroundColor: ["#2ecc71", "#e74c3c", "#f1c40f", "#3498db", "#95a5a6", "#9b59b6", "#34495e", "#2ecc71", "#e74c3c", "#f1c40f", "#3498db", "#95a5a6", "#9b59b6", "#34495e"],
                    data: data
                }]
            },

            // Configuration options go here
            options: {
                legend: {
                    display: true,
                    position: 'bottom',
                },
                animation: {
                    onComplete: function (animation) {
                        var firstSet = animation.chart.config.data.datasets[0].data,
                            dataSum = firstSet.reduce((accumulator, currentValue) => accumulator + currentValue);

                        if (typeof firstSet !== "object" || dataSum === 0) {
                            document.getElementById('no-timeMgmntPi-data').style.display = 'flex';
                            document.getElementById('timeMgmntPi').style.display = 'none'
                        } else {
                            document.getElementById('no-timeMgmntPi-data').style.display = 'none';
                        }
                    }
                }
            }
        });

        let blabels = [];
        let bdata = [];
        props.AdvReportData.subject_wise_analysis.forEach(subject => {
            blabels.push(subject.subject_name);
            bdata.push(subject.subject_questions.time_taken / 60000);
        });
        var canvas = document?.getElementById("timeMgmntBar");
        var ctx = canvas?.getContext("2d");
        /* var ctx = document.getElementById('myChart').getContext('2d'); */
        var myBarChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: blabels,
                datasets: [{
                    label: '',
                    backgroundColor: ["#2ecc71", "#e74c3c", "#f1c40f", "#3498db", "#95a5a6", "#9b59b6", "#34495e", "#2ecc71", "#e74c3c", "#f1c40f", "#3498db", "#95a5a6", "#9b59b6", "#34495e"],
                    data: bdata
                }]
            },

            // Configuration options go here
            options: {
                legend: {
                    display: false,
                    position: 'bottom',
                },
                animation: {
                    onComplete: function (animation) {
                        var firstSet = animation.chart.config.data.datasets[0].data,
                            dataSum = firstSet.reduce((accumulator, currentValue) => accumulator + currentValue);

                        if (typeof firstSet !== "object" || dataSum === 0) {
                            document.getElementById('no-timeMgmntBar-data').style.display = 'block';
                            document.getElementById('timeMgmntBar').style.display = 'none'
                        } else {
                            document.getElementById('no-timeMgmntBar-data').style.display = 'none';
                            document.getElementById('timeMgmntBar').style.marginTop = '1.5em'
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        barPercentage: 0.4
                    }]
                }
            }
        });


    }
    useEffect(() => {
        timeMgmntPi()
    }, [])

    return (<>
        <div className="row m-t-2e border-b-1" >
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-b-1">
                <h3>Time Management Analysis (Subject wise)</h3>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-b-1">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 border-r-1">
                        <p className="text-center f-w-600">No. of Questions appeared from section</p>
                        <canvas id="timeMgmntPi" ></canvas>
                        <p className="text-center" id="no-timeMgmntPi-data" style={{ height: "inherit" }}><span className="m-auto">No data
                            available to display</span></p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 height-400">
                        <canvas id="timeMgmntBar"></canvas>
                        <p className="text-center" id="no-timeMgmntBar-data">Nothing to display</p>
                        <p className="text-center">Test duration : {props.AdvReportData.test_info.duration} mins<br />For 1 question : {props.AdvReportData.test_info.duration / props.AdvReportData.test_info.total_questions > 0 ? props.AdvReportData.test_info.duration / props.AdvReportData.test_info.total_questions : 0} mins</p>
                    </div>
                </div>
            </div>
            {
                props.AdvReportData.subject_wise_analysis && props.AdvReportData.subject_wise_analysis.map((item, index) => {
                    const locMax = ((props.AdvReportData.test_info.duration/props.AdvReportData.test_info.total_questions) * item.questions_count).toFixed(0)
                    return(
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-b-1 m-t-2e"
                    >
                        <h5> {index + 1} .  {item.subject_name} </h5>
                        <p>Total questions: <span className="f-w-600">{item.questions_count}</span></p>
                        <div className="row m-t-2e">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center">
                                <Gauge
                                    value={locMax>0?((props.AdvReportData.test_info.duration/props.AdvReportData.test_info.total_questions) * item.questions_count).toFixed(3):"NA"}
                                    label={""}
                                    width={200}
                                    height={200}
                                    max={locMax>0 ? locMax : 100}
                                    // max={((props.AdvReportData.test_info.duration/props.AdvReportData.test_info.total_questions) * item.questions_count).toFixed(0)}
                                    color={"blue"}
                                    topLabelStyle={{ display: "none" }}
                                    valueLabelStyle={{fontSize:"18px" ,fontWeight:"bold"}}
                                    valueFormatter={number => `${number}`}
                                />
                                <p className="text-center p-0">Available time in minutes</p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center">
                                <Gauge
                                    value={locMax>0 ?millisToMinutesAndSeconds((item.subject_questions.time_taken)):"NA"}
                                    label={""}
                                    width={200}
                                    height={200}
                                    max={locMax>0 ? locMax : 100}
                                    // max={((props.AdvReportData.test_info.duration/props.AdvReportData.test_info.total_questions) * item.questions_count).toFixed(0)}
                                    color={"blue"}
                                    topLabelStyle={{  }}
                                    valueLabelStyle={{fontSize:"18px" ,fontWeight:"bold"}}
                                    
                                    valueFormatter={number => `${number}`}
                                />
                                <p className="text-center p-0">Time taken in minutes</p>
                            </div>
                           { ((props.AdvReportData.test_info.duration/props.AdvReportData.test_info.total_questions) * item.questions_count) - (item.subject_questions.time_taken/60000).toFixed(3)>0?
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center"
                            >
                                
        
                                <Gauge
                                    value={locMax>0 ?millisToMinutesAndSeconds(((props.AdvReportData.test_info.duration*60000/props.AdvReportData.test_info.total_questions) * item.questions_count) - (item.subject_questions.time_taken)):"NA"}
                                    label={""}
                                    width={200}
                                    height={200}
                                    max={locMax>0 ? locMax : 100}
                                    // max={((props.AdvReportData.test_info.duration/props.AdvReportData.test_info.total_questions) * item.questions_count).toFixed(0)}
                                    color={"green"}
                                    topLabelStyle={{ display: "none" }}
                                    valueFormatter={number => `${number}`}
                                    valueLabelStyle={{fontSize:"18px" ,fontWeight:"bold"}}
                                />
                                <p className="text-center p-0">Time Saved in minutes</p>
                            </div>
                            :<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center"
                            >
                                  <Gauge
                                    value={((item.subject_questions.time_taken/60000) - ((props.AdvReportData.test_info.duration/props.AdvReportData.test_info.total_questions) * item.questions_count)).toFixed(3)}
                                    label={""}
                                    width={200}
                                    height={200}
                                    topLabelStyle={{ display: "none" }}
                                    valueFormatter={number => `${number}`}
                                    valueLabelStyle={{fontSize:"18px" ,fontWeight:"bold"}}
                                />
                             
                                <p className="text-center p-0">Extra Time Taken in minutes</p>
                            </div>}
                        </div>
                    </div>
                )})

            }
        </div>
    </>)
}
export default TimeManagementAanalysis;
