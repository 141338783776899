import React from "react"

const SubmitStatus=()=>{
    return(<>
    <div className="overlay-wrapper overlay-white" >
  <div className="text-box">
    <div className="loader" id="loader-7"></div>
    <p className="lead text-bold mb-2"> Please wait...</p>
    <p className="text-gray">Submitting Your Exam</p>
  </div>
</div>
</>)
}
export default SubmitStatus;