import React from "react";
import { Chart } from 'chart.js';
import AdvanceResults from ".";
const SubjectWiseAnalysis = (props, data) => {
  const calcPercentage = (obtainMark, totalMark) => {
    let per = (obtainMark * 100) /totalMark;
    return (per.toFixed(2))
  }

  return (<>
    {
      props.AdvReportData.subject_wise_analysis &&
      <div className="row m-t-2e border-b-1">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-b-1">
          <h3>Subject wise analysis</h3>
        </div>
        {props.AdvReportData.subject_wise_analysis.map((item, index) => (
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-b-1 m-t-2e"
          >
            <h5> {index + 1} . {item.subject_name}</h5>
            <p>Total questions: <span className="f-w-600"> {item.questions_count}</span></p>
            <div className="row m-t-2e">
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
                <div className="progress mx-auto"
                >
                  <span className="progress-left">
                    {(item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100) >= 50 &&
                      <span className="progress-bar border-primary"
                        style={{ transform: "rotate(180deg)" }}> </span>
                    }
                    {(item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100) > 0 && (item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100) < 51 &&
                      <span className="progress-bar border-primary"
                      style={{ transform: `rotate(${(item.subject_questions.marks_obtained / item.subject_questions.maximum_marks) * 360}deg)` }}
                    ></span>}
                  </span>
                  <span className="progress-right">
                    {(item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100) > 50 && (item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100) < 100 &&
                      <span className="progress-bar border-primary"
                        style={{ transform: `rotate(${(100 - (item.subject_questions.marks_obtained / item.subject_questions.maximum_marks *100))*3.6}deg)` }}>
                      </span>}
                    {(item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100) == 100 && <span className="progress-bar border-primary" style={{ transform: "rotate(180deg)" }}
                    ></span>}
                  </span>
                
                  <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                    <div className="h4 font-weight-bold">
                      {item.subject_questions.marks_obtained > 0 ? item.subject_questions.marks_obtained : 0}/{item.subject_questions.maximum_marks > 0 ? item.subject_questions.maximum_marks : 0}

                    </div>
                  </div>
                </div>

                <p>Marks</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
                <div className="progress mx-auto"
                >
                  <span className="progress-left">
                    {(item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100) >= 50 &&
                      <span className="progress-bar border-primary"
                        style={{ transform: "rotate(180deg)" }}> </span>
                    }
                    {(item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100) > 0 && (item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100) < 50 &&
                      <span className="progress-bar border-primary"
                      style={{ transform: `rotate(${(item.subject_questions.marks_obtained / item.subject_questions.maximum_marks ) * 360}deg)` }}
                    ></span>}
                  </span>
                  <span className="progress-right">            
                    {(item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100) > 50 && (item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100) < 100 &&
                      <span className="progress-bar border-primary"
                        style={{ transform: `rotate(${ (100 -((item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100))) * 3.6}deg)` }}>
                      </span>}
                    {(item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100) == 100 && <span className="progress-bar border-primary" style={{ transform: "rotate(180deg)" }}
                    ></span>}
                  </span>
                  <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                    <div className="h4 font-weight-bold">
                      {item.subject_questions.marks_obtained / item.subject_questions.maximum_marks * 100 > 0 ? calcPercentage(item.subject_questions.marks_obtained, item.subject_questions.maximum_marks) : 0}
                    </div>
                  </div>
                </div>

                <p>Percentage</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
                <div className="progress mx-auto">

                  <span className="progress-left">
                    {
                      (item.subject_questions.questions_answered / item.questions_count * 100) >= 50 &&
                      <span className="progress-bar border-primary"
                        style={{ transform: "rotate(180deg)" }}> </span>
                    }
                    {(item.subject_questions.questions_answered / item.questions_count * 100) > 0 && (item.subject_questions.questions_answered / item.questions_count * 100) < 51 &&
                      <span className="progress-bar border-primary"
                        style={{ transform: `rotate(${(item.subject_questions.questions_answered / item.questions_count) * 360}deg)` }}
                      ></span>
                      }
                  </span>
                  <span className="progress-right">
                    {(item.subject_questions.questions_answered / item.questions_count * 100) > 50 && (item.subject_questions.questions_answered / item.questions_count * 100) < 100 &&
                      <span className="progress-bar border-primary"
                        style={{ transform: `rotate(${(100-(item.subject_questions.questions_answered / item.questions_count * 100)) * 3.6}deg)` }}
                      ></span>
                      }
                    {(item.subject_questions.questions_answered / item.questions_count * 100) == 100 &&
                      <span className="progress-bar border-primary"
                        style={{ transform: "rotate(180deg)" }}
                      ></span>}
                  </span>
                  <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                    <div className="h4 font-weight-bold">
                      {item.subject_questions.questions_answered > 0 ? item.subject_questions.questions_answered : 0}/{item.questions_count > 0 ? item.questions_count : 0}
                    </div>
                  </div>
                </div>

                <p>Answered {item.subject_questions.questions_answered}</p>
                <p>Unanswered {item.subject_questions.questions_unanswered} </p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
                <div className="progress mx-auto"
                >
                  <span className="progress-left">
                    {
                      (item.subject_questions.correct_answers / item.questions_count * 100) >= 50 &&
                      <span className="progress-bar border-primary"
                        style={{ transform: "rotate(180deg)" }}
                      ></span>}
                    {(item.subject_questions.correct_answers / item.questions_count * 100) > 0 && (item.subject_questions.correct_answers / item.questions_count * 100) < 50 &&
                      <span className="progress-bar border-primary"
                        style={{ transform: `rotate(${(item.subject_questions.correct_answers / item.questions_count) * 360}deg)` }}
                      ></span>}
                  </span>
                  <span className="progress-right">
                    {(item.subject_questions.correct_answers / item.questions_count * 100) > 50 && (item.subject_questions.correct_answers / item.questions_count * 100) < 100 &&
                      <span className="progress-bar border-primary"
                        style={{ transform: `rotate(${(100 -(item.subject_questions.correct_answers / item.questions_count *100 ))*3.6}deg)` }}
                      ></span>}
                    {(item.subject_questions.correct_answers / item.questions_count * 100) == 100 &&
                      <span className="progress-bar border-primary"
                        style={{ transform: "rotate(180deg)" }}
                      ></span>}
                  </span>
                  <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                    <div className="h4 font-weight-bold">
                      {item.subject_questions.correct_answers > 0 ? item.subject_questions.correct_answers : 0}/{item.questions_count > 0 ? item.questions_count : 0}
                    </div>
                  </div>
                </div>

                <p>Correct {item.subject_questions.correct_answers} </p>
                <p>Incorrect {item.subject_questions.incorrect_answers}</p>
              </div>
            </div>    
          </div>))}
      </div>
    }


  </>)
}
export default SubjectWiseAnalysis;
