import React, { useEffect, useState } from "react"
import "./registration.scss"
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import api from "../../api";

const Registration = (props) => {
    const [state, setState] = useState({ email: "", name: "", number: "", educationQualification: "", address: "" });
    const [dynamicField, setDynamicField] = useState([])
    let history = useHistory();

    function handleClick() {
        history.goBack();
    }

    function proccedToNavigation() {
        history.push("/instructions")
    }

    const getFieldApi = () => {
        let request = {
            url: `/v1/custom_field/${props.state.institute_data.institute_id}`,

        }
        api.getAuth(request).then((response) => {
            if (response.data.validate == true) {
                const value = response.data.result
                value.forEach((item, index) => {
                    item["field_value"] = ""
                })
                setDynamicField(value)
            }
        })
    }

    const setDynamicFielddata = (index, data) => {
        const value = dynamicField
        value[index].field_value = data;
        setDynamicField(value)
    }

    const postRegistrationApi = async () => {
        let request = {
            url: `/v1/custom_field/register/${props.state.institute_data.institute_id}`,
            data: {
                "test_id": props.state.test_data.test_id,
                "name": state.name,
                "mobile": state.number,
                "address": state.address,
                "education": state.educationQualification,
                "email": state.email,
                "field_data": dynamicField
            }
        }
        await api.post(request).then((response) => {
            props.dispatch({ type: 'REGISTRATION', registrationdata: state })
            proccedToNavigation()
        })
    }

    useEffect(() => {
        getFieldApi()

    }, [])

    const ValidateEmail = (data) => {
        if(data.length > 0){
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data)) {
            return (true)
        }

        alert("You have entered an invalid email address!")
        return (false)
    }
    return(true)
    }

    const validateDynamicField = () => {
        let value = true 
        dynamicField.forEach((item, index) => {
            if (item.is_required == 1) {
                if (item.field_value.length <= 0) {
                    
                    value =  false
                   
                }
              
            }
        })
        if(value == false){
            alert("You have entered required field ")
        }
        
        return value;
    }

    const phonenumber = (inputtxt) => {
        var phoneno = /^\d{10}$/;
        if ((inputtxt.match(phoneno))) {
            return true;
        }
        else {
            alert("You have entered an invalid mobile number!")
            return false;
        }
    }
    const Name = (inputtxt) => {
        var phoneno = /^\d{10}$/;
        if (inputtxt.length>0) {
            return true;
        }
        else {
            alert("Please enter your name !")
            return false;
        }
    }

    const onProceed = () => {
        if ( ValidateEmail(state["email"]) && phonenumber(state["number"]) && Name(state["name"]) && validateDynamicField()) {
            postRegistrationApi()
        }
    }

    const setValue = (fieldname, valuedata) => {
        if (fieldname == null || fieldname == "") {
            return false;
        }
        else {
            if (fieldname.length > 0) {
                let value = { ...state };
                value[fieldname] = valuedata
                setState(value)
            }
        }
    }

    return (<>
    
        <div class="s1-v">
            <div class="header">
                <div>
                    <a href="#">
                        <button>Online test</button>
                    </a>
                </div>
                <p>{props.state.test_data.test_name}</p>
                {/* <a>
                    <button>Test Preview</button>
                </a> */}
            </div>
            <div className="reg-height">
                <div class="h1-gg">
                    <h1>Registration Form</h1>
                    <div>
                       <p className="flatLine"></p>
                        <div >
                            <div>
                                <div className="labeldiv">
                                    <p className="labelname"> Name <span style={{ color: "#F30F0F" }}>*</span></p>
                                    <input maxLength="150" className="inputValue" placeholder="Type Name Here" value={state["name"]} onChange={(e) => setValue("name", e.target.value)} />
                                </div>
                                <div className="labeldiv">
                                    <p className="labelname"> Mobile Number <span style={{ color: "#F30F0F" }}>*</span></p>
                                    <input maxLength="10" className="inputValue"  placeholder="Enter Mobile Number " value={state["number"]} onChange={(e) => {  setValue("number", e.target.value) }} />
                                </div>

                                <div className="labeldiv">
                                    <p className="labelname"> Email Address</p>
                                    <input className="inputValue"  maxLength="100" placeholder="Type Email Address Here" value={state["email"]} onChange={(e) => setValue("email", e.target.value)} />
                                </div>
                                <div className="labeldiv">
                                    <p className="labelname"> Address</p>
                                    <input maxLength="250" className="inputValue" placeholder="Type  Address Here" value={state["address"]} onChange={(e) => { setValue("address", e.target.value) }} />
                                </div>
                                <div className="labeldiv">
                                    <p className="labelname"> Education Qualification</p>
                                    <input className="inputValue" maxLength="50" placeholder="e.g., 12th Standard" value={state["educationQualification"]} onChange={(e) => { setValue("educationQualification", e.target.value) }} />
                                </div>
                                {dynamicField.length > 0 &&
                                    dynamicField.map((item, index) => (
                                        <div className="labeldiv">
                                            <p className="labelname"> {item.field_name} {item.is_required == 1 && <span style={{ color: "#F30F0F" }}>*</span>}</p>
                                            <input className="inputValue" maxLength={item?.char_limit} placeholder={"Enter " + item.field_name} value={dynamicField[item.field_value]} onChange={(e) => { setDynamicFielddata(index, e.target.value) }} />
                                        </div>
                                    ))

                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div style={{display:"flex" , alignItems:"center", justifyContent:"center"}}>
                <div style={{ display: "flex", flexDirection: "row", marginTop: "25px", justifyContent: "space-around" , alignItems:"center", width:"70%" }}>
                    <button className="goBackButton" onClick={() => { handleClick() }}>
                        <p className="goBacklabel"> {"<<" + " " + "Go Back"}</p>
                    </button>
                    <div className="stateLineIn"></div>
                    <button className="proccedButton" onClick={() => { onProceed() }}>
                        <p className="proccedlabel"> Proceed</p>
                    </button>
                </div>
            </div>

        </div>
    </>)
}

const mapStateToProps = state => ({ state: state })
export default connect(mapStateToProps)(Registration)